import {Controller} from "stimulus";
import random from 'lodash/random'

export default class extends Controller {
    connect() {
        this.element.addEventListener('crop', (e) => {
            const data = e.detail
            if (data && data.width) this.handleCrop(data)
        })
        const button = this.element.querySelector('button')
        button.addEventListener('click', () => this.open())
    }

    open() {
        const html = `
              <div class="modal modal-lg fade" tabindex="-1" role="dialog" style="max-width: 100%; width: 100%;">
                <div class="modal-dialog" role="document" style="max-width: 1050px">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title">Image Crop</h4>
                      <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body"></div>
                    <div class="modal-footer">
                      <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
                      <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
        `
        $('#crop-modal')
            .html(html)
            .find('.modal').on('shown.coreui.modal', () => {
                const reactor = document.createElement('div')
                reactor.dataset.controller = "react"
                reactor.dataset.reactComponent = "image-crop"
                reactor.dataset.reactProps = this.element.dataset.props
                $('#crop-modal').data('funBox', this.element)
                $('#crop-modal').find('.modal-body').append(reactor)
                $('#crop-modal').find('button[type=submit]').on('click', (e) => {
                    const pickers = $(this.element).closest('div[data-controller=photo-picker]')
                    if (pickers.length) {
                        e.preventDefault()
                        e.stopPropagation()
                        pickers[0].dispatchEvent(new CustomEvent('photo-picker:reload'))
                        $('#crop-modal').find('.modal').modal('hide')
                    }
                })
            })
            .modal('toggle')
    }

    disconnect() {
    }

    handleCrop(data) {
        const root = this.element
        root.querySelector('.width').setAttribute('value', data.width)
        root.querySelector('.height').setAttribute('value', data.height)
        root.querySelector('.x').setAttribute('value', data.x)
        root.querySelector('.y').setAttribute('value', data.y)
    }

    get newCID() {
        return random(100000, 999999).toString(36)
    }
}
