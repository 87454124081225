import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        const selector = $(this.element).find('[data-mode-selector]')
        const ffmSelector = $(this.element).find('[id*=free_form]')
        selector.on('change', function() {
            const value = $(this).val()
            const fieldset = $(this).closest('.sortable')
            $(fieldset).find('[data-mode]').hide()
            if (value)
                $(fieldset).find(`[data-mode*=${value}]`).show()
            if (value === 'label' || value === 'object') {
                ffmSelector.find('option').attr('disabled', true).hide()
                ffmSelector.find('option:first').removeAttr('disabled').show()
                window.freeFormModes[value].forEach(mode => {
                    ffmSelector.find(`option[value=${mode}]`).removeAttr('disabled').show()
                })
            }
        }).trigger('change')

    }
}
