import { Controller } from 'stimulus'

export default class extends Controller {
    connect() {
        this.element.querySelectorAll('.choose-photo').forEach(button => button.addEventListener('click', () => this.openPicker()))
        this.element.querySelectorAll('.remove-photo').forEach(button => button.addEventListener('click', () => this.removePhoto()))
        this.element.addEventListener('photo-picker:reload', () => this.reload())
    }

    disconnect() {
    }

    openPicker() {
        $('#photo-picker-modal').load("/admin/photos?modal=1", () => {
            $('#photo-picker-modal')
                .on('click', '.photo input[type=checkbox]', (event) => {
                    this.selectPhoto(event.currentTarget)
                    event.stopPropagation()
                })
                .on('click', '.photo', (event) => $(event.currentTarget).find('input[type=checkbox]').trigger('click'))
                .on('click', 'button.use-photo', () => this.useSelectedPhoto())
                .on('change', 'input[type=file]', (e) => this.allowUpload(e))
                .find('.modal').modal('toggle')
        })
    }

    allowUpload(e) {
        const el = $(e.currentTarget)
        if (el.val().length > 0) {
            el.closest('.form-group').next().removeAttr('disabled').prop('disabled', null)
        }
    }

    selectPhoto(checkbox) {
        const pickButton = $('#photo-picker-modal button.use-photo')
        const selectedPhotos = $('.photo input[type=checkbox]:checked')
        if (!this.element.dataset.multiple) {
            selectedPhotos.each((i, photo) => {
                if (photo.value !== checkbox.value) {
                    $(photo).prop('checked', false).removeAttr('checked')
                }
            })
        }
        if (selectedPhotos.length) {
            pickButton.removeAttr('disabled')
        } else {
            pickButton.attr('disabled', true)
        }
    }

    useSelectedPhoto() {
        if (this.element.dataset.multiple) {
            // what to do with multiple?
        } else {
            const selectedPhoto = $('.photo input[type=checkbox]:checked')
            const value = selectedPhoto.attr('value')
            this.element.querySelector('.photo-id-field').setAttribute('value', value)
            this.removeCrop()
            $('#photo-picker-modal').find('.modal').modal('hide')
            this.reload()
        }
    }

    removePhoto() {
        if (this.element.dataset.multiple) {
            // what to do with multiple?
        } else {
            this.removeCrop()
            this.element.querySelector('.photo-id-field').setAttribute('value', '')
            this.reload()
        }
    }

    removeCrop() {
        const el = this.element
        'x y width height'.split(' ').forEach(selector => {
            const field = el.querySelector('.' + selector)
            if (field)
                field.setAttribute('value', '')
        })
    }

    reload() {
        const data = {
            object_name:  this.element.dataset.objectName,
            object_class: this.element.dataset.objectClass,
            object_id:    this.element.dataset.objectId,
            attribute:    this.element.dataset.attribute,
            constraints:  this.element.dataset.constraints,
            label_text:   this.element.dataset.label_text,
            value:        this.element.querySelector('.photo-id-field').getAttribute('value')
        }
        const el = this.element
        'x y width height'.split(' ').forEach(selector => {
            const field = el.querySelector('.' + selector)
            if (field)
                data[selector] = field.getAttribute('value')
        })
        const path = '/admin/photos/picker.html'
        const url = new URL(document.location.origin + path)
        url.search = new URLSearchParams(data)
        fetch(url).then(response => {
            response.text().then(html => {
                $(this.element).replaceWith(html)
            })
        })
    }
}
