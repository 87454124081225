import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        const modal = this.element.nextSibling
        this.caption = modal.querySelector('input[type=text]')
        this.caption.addEventListener('change', () => {
            if (this.element.dataset.originalTitle !== this.caption.value) {
                document.querySelector('input[type=submit]').value = 'Save changes'
            }
            this.setTooltipFromCaption()
        })
        this.setTooltipFromCaption()
    }

    setTooltipFromCaption() {
        this.element.dataset.originalTitle = this.caption.value || 'No caption'
    }
}
