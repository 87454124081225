import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        const selector = $(this.element).find('[data-variant-selector]')
        selector.on('change', function() {
            const value = $(this).val()
            const fieldset = $(this).closest('.list-group-item')
            $(fieldset).find('[data-variant]').hide()
            if (value)
                $(fieldset).find(`[data-variant*=${value}]`).show()
        }).trigger('change')

    }
}
