// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "@coreui/coreui/dist/js/coreui"
import "components"
import "controllers"
import "../stylesheets/cms.scss"
import "image-picker"

require("@rails/ujs").start()
$(document).ready(function() {
    $('.c-sidebar').sidebar()
    $('[rel=tooltip]').tooltip()

    if (window.accordionToRestore) {
        restoreAccordion(window.accordionToRestore, 'accordion')
    }
    $('select.image-picker').imagepicker()
})

const restoreAccordion = function(key, panelId) {
    const activePanel = localStorage.getItem(key)
    if (activePanel) {
        $('#' + activePanel).addClass('show')
    }
    $('#' + panelId).on('shown.coreui.collapse', function(e) {
        saveAccordionPanel(key, e.target.id)
    })
    $('#' + panelId).on('hide.coreui.collapse', function(e) {
        saveAccordionPanel(key, null)
    })
}

const saveAccordionPanel = function(key, id) {
    localStorage.setItem(key, id)
}
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
