import { Controller } from 'stimulus'

export default class extends Controller {
    connect() {
        this.element.querySelectorAll('.choose-video').forEach(button => button.addEventListener('click', () => this.openPicker()))
        this.element.querySelectorAll('.remove-video').forEach(button => button.addEventListener('click', () => this.removeVideo()))
        this.element.addEventListener('video-picker:reload', () => this.reload())
    }

    disconnect() {
    }

    openPicker() {
        $('#video-picker-modal').load("/admin/videos?modal=1", () => {
            $('#video-picker-modal')
                .on('click', '.video input[type=checkbox]', (event) => {
                    this.selectVideo(event.currentTarget)
                    event.stopPropagation()
                })
                .on('click', '.video', (event) => $(event.currentTarget).find('input[type=checkbox]').trigger('click'))
                .on('click', 'button.use-video', () => this.useSelectedVideo())
                .find('.modal').modal('toggle')
        })
    }

    selectVideo(checkbox) {
        const pickButton = $('#video-picker-modal button.use-video')
        const selectedVideos = $('.video input[type=checkbox]:checked')
        selectedVideos.each((i, video) => {
            if (video.value !== checkbox.value) {
                $(video).prop('checked', false).removeAttr('checked')
            }
        })
        if (selectedVideos.length) {
            pickButton.removeAttr('disabled')
        } else {
            pickButton.attr('disabled', true)
        }
    }

    useSelectedVideo() {
        if (this.element.dataset.multiple) {
            // what to do with multiple?
        } else {
            const selectedVideo = $('.video input[type=checkbox]:checked')
            const value = selectedVideo.attr('value')
            this.element.querySelector('.video-id-field').setAttribute('value', value)
            $('#video-picker-modal').find('.modal').modal('hide')
            this.reload()
        }
    }

    removeVideo() {
        if (this.element.dataset.multiple) {
            // what to do with multiple?
        } else {
            const el = this.element
            'x y width height'.split(' ').forEach(selector => {
                const field = el.querySelector('.' + selector)
                if (field)
                    field.setAttribute('value', '')
            })
            el.querySelector('.video-id-field').setAttribute('value', '')
            this.reload()
        }
    }

    reload() {
        const data = {
            object_name:  this.element.dataset.objectName,
            object_class: this.element.dataset.objectClass,
            object_id:    this.element.dataset.objectId,
            attribute:    this.element.dataset.attribute,
            label_text:   this.element.dataset.label_text,
            value:        this.element.querySelector('.video-id-field').getAttribute('value')
        }
        const path = '/admin/videos/picker.html'
        const url = new URL(document.location.origin + path)
        url.search = new URLSearchParams(data)
        fetch(url).then(response => {
            response.text().then(html => {
                $(this.element).replaceWith(html)
            })
        })
    }
}
