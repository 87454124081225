const context = require.context("components", true, /_component\.js$/)

const components = context.keys().reduce(function(hash, key) {
    const bits = key.split('/')
    const name = bits[bits.length - 1].replace('_component.js', '').replace('_', '-')
    hash[name] = context(key).default
    return hash
}, {})

window.ReactComponents = components
