import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        const { min, max, zen } = this.element.dataset
        const input = this.element.querySelector('input, textarea')
        const counter = document.createElement('div')
        this.element.appendChild(counter)
        const show = () => {
            const { value } = input
            if (!value || value === '') {
                counter.className = 'float-right text-default'
                counter.textContent = '0 words'
            } else {
                const numWords = value.replace(/\s+/g, ' ').split(' ').length
                let className = "text-"
                if (numWords < min || numWords > max) className += 'danger';
                else if (numWords > zen) className += 'warning'
                else className += 'default'
                counter.className = `float-right ${className}`
                counter.textContent =  `${numWords} word${numWords > 1 ? 's' : ''}`
            }
        }
        show()
        input.addEventListener('keyup', show)
    }
}
