import React from 'react'
import ReactCrop, {makeAspectCrop} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

export default class extends React.PureComponent {
    state = {
        src: null,
        loading: false,
        crop: {
            aspect: 1920 / 1080,
            width: 80,
            height: 80,
            x: 10,
            y: 10,
        }
    }

    handleCrop = this._handleCrop.bind(this)
    handleComplete = this._handleComplete.bind(this)
    handleImageLoaded = this._handleImageLoaded.bind(this)

    ref = React.createRef()

    render() {
        if (!this.state.src) return null;
        return (
            <div ref={this.ref}>
                <ReactCrop src={this.state.src}
                           ruleOfThirds
                           onChange={this.handleCrop}
                           onComplete={this.handleComplete}
                           onImageLoaded={this.handleImageLoaded}
                           imageStyle={{maxWidth: 1000, maxHeight: 700}}
                           crop={this.state.crop}
                />
            </div>
        )
    }

    constructor(props) {
        super(props)
        if (props.src && !this.state.src && !this.state.loading) {
            this._preloadImage()
            this.state.loading = true
        }
    }

    componentDidUpdate(props) {
        if (props.src && !this.state.src && !this.state.loading) {
            this._preloadImage()
            this.setState({loading: true})
        }
    }

    _handleCrop(crop, pixelCrop) {
        this.setState({crop})
        if (!this.props.crop) {
            this.handleCropped(pixelCrop)
        }
    }

    _handleComplete(crop, pixelCrop) {
        this.handleCropped(pixelCrop)
    }

    _preloadImage() {
        const img = new Image;
        let {constraints} = this.props;
        let {crop} = this.props;
        img.onload = () => {
            this.width = img.width
            this.height = img.height
            // if (crop && crop.x && constraints.aspect && ((crop.width / crop.height).toFixed(1) !== constraints.aspect.toFixed(1))) {
            //     crop = constraints.crop
            // }
            if (crop && crop.x) {
                this.setState({
                    crop: {
                        unit: '%',
                        x: crop.x / img.width * 100,
                        y: crop.y / img.height * 100,
                        width: crop.width / img.width * 100,
                        height: crop.height / img.height * 100,
                    }
                })
            } else {
                if (constraints.aspect) {
                    // constraints.width = constraints.width || 50
                    constraints.unit = constraints.unit || '%'
                    this.setState({
                        crop: makeAspectCrop(constraints, img.width, img.height),
                    })
                } else {
                    this.setState({
                        crop: constraints
                    })
                }
            }
            this.setState({ src: img.src })
        }
        img.src = this.src
    }

    _handleImageLoaded(image, pixelCrop) {
        if (!this.props.crop && pixelCrop && pixelCrop.height > 0) {
            this.handleCropped(pixelCrop)
        }
    }

    handleCropped(pixelCrop) {
        if (this.ref && this.ref.current) {
            if (pixelCrop.unit === '%') {
                pixelCrop.x = pixelCrop.x / 100 * this.width
                pixelCrop.y = pixelCrop.y / 100 * this.height
                pixelCrop.width = pixelCrop.width / 100 * this.width
                pixelCrop.height = pixelCrop.height / 100 * this.height
            }
            const event = new CustomEvent("crop", { detail: pixelCrop })
            $('#crop-modal').data('funBox').dispatchEvent(event)
        }
    }

    get src() {
        return this.props.src
    }
}
