import { Controller } from 'stimulus'

export default class extends Controller {
    connect() {
        const video = this.element.querySelector('video')
        const button = this.element.querySelector('button.make-thumb')
        const input = this.element.querySelector('input.thumb-time')
        button.addEventListener('click', () => {
            const { currentTime } = video
            input.setAttribute('value', currentTime)
            button.innerHTML = `Make Thumb at ${currentTime.toFixed(1)} seconds`
        })
    }
}