import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        const link = this.element.querySelector('a[data-insert-fields]')
        link.addEventListener('click', (event) => {
            event.preventDefault()
            const method = link.dataset.insertFields
            const content = document.getElementById(`${method}_fields`).innerHTML
            this.insertFields(link, method, content)
        })
    }

    insertFields(link, method, content) {
        const newId = (new Date()).getTime()
        const regexp = new RegExp("new_" + method, "g")
        const newContent = $(content.replace(regexp, newId))
        $(this.element).find('.list-group, #accordion').append(newContent)
    }
}
