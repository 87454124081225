import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        let statusClass, helpText
        const status = this.element.dataset.status

        switch (status) {
            case 'never':
                statusClass = 'secondary'
                helpText = 'This display is not yet in use.'
                break
            case 'connected':
                statusClass = 'success'
                helpText = 'This display is working normally.'
                break
            case 'disconnected':
                statusClass = 'warning'
                helpText = 'This display is not connected'
                break
            case 'lost':
                statusClass = 'danger'
                helpText = 'This display cannot be reached.'
                break
            default:
                statusClass = 'secondary'
                helpText = null       }

        this.element.classList.add(`badge-${statusClass}`)
        this.element.innerHTML = helpText || status
    }
}
